
import {
  defineComponent, inject, reactive, watch, Ref, provide,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useRequisicaoAutorizacaoBase } from '@/composables/MeuSistema/RequisicaoAutorizacaoBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarFormaPagamento from '@/components/Cadastros/Financeiro/SelecionarFormaPagamento.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoFrete } from '@/models/Enumeradores/Compartilhados/ETipoFrete';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarColaborador from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarColaborador.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';
import SelecionarMarcadorNotaFiscal from '@/components/Fiscal/NotasFiscais/SelecionarMarcadorNotaFiscal.vue';
import FaturamentoItens from '@/components/Fiscal/Faturamentos/FaturamentoItens.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import {
  IVenda,
} from '@/models/Entidades/Vendas/IVenda';
import {
  INotaFiscal, INotaFiscalAnexo, INotaFiscalDestinatario, INotaFiscalDetalhePagamento, INotaFiscalEmitente, INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalMarcador, INotaFiscalTransporte, INotaFiscalTransporteVolume,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { ETipoValorMovimentacao } from '@/models/Enumeradores/ETipoValorMovimentacao';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { EOrigemValorItem } from '@/models/Enumeradores/EOrigemValorItem';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';
import { IFormaPagamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import { IDTOCenarioFiscalDefinicaoMovimentacao } from '@/models/DTO/Tributacoes/IDTOCenarioFiscalDefinicaoMovimentacao';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import NotaFiscalImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalImpostos.vue';
import NotaFiscalFrete from '@/components/Fiscal/NotasFiscais/NotaFiscalFrete.vue';
import NotaFiscalDetalhesPagamento from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhesPagamento.vue';
import NotaFiscalAnexos from '@/components/Fiscal/NotasFiscais/NotaFiscalAnexos.vue';
import NotaFiscalHistorico from '@/components/Fiscal/NotasFiscais/NotaFiscalHistorico.vue';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';
import { ETipoEmissao } from '@/models/Enumeradores/Fiscal/ETipoEmissao';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';
import { IRetornoDFe } from '@/core/models/IRetornoDFe';
import { EStatusDFe } from '@/core/models/Enumeradores/EStatusDFe';
import { useFocusBase } from '@/core/composables/FocusBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import { IDTOFaturamento } from '@/models/DTO/Fiscal/IDTOFaturamento';
import ServicoFaturamento from '@/servicos/Fiscal/ServicoFaturamento';

export default defineComponent({
  name: 'FaturamentoVendasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    faturamento: {
      type: Object as () => IDTOFaturamento,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    EditorHtml,
    SelecionarData,
    SelecionarColaborador,
    SelecionarPessoa,
    SelecionarFormaPagamento,
    SelecionarMarcadorNotaFiscal,
    FaturamentoItens,
    NotaFiscalImpostos,
    NotaFiscalFrete,
    NotaFiscalDetalhesPagamento,
    NotaFiscalAnexos,
    NotaFiscalHistorico,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const servicoFaturamento = new ServicoFaturamento();
    const servicoFormaPagamento = new ServicoFormaPagamento();
    servicoFormaPagamento.requisicaoSistema();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const servicoSistema = new ServicoSistema();
    const servicoPessoa = new ServicoPessoa();
    servicoPessoa.requisicaoSistema();
    const {
      telaBase,
      apresentarMensagemSucesso, apresentarMensagemAlerta, preencherEmpresasDisponiveis, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      focusBase, elementosFocaveis, focusElemento, proximoElemento, elementoAnterior,
    } = useFocusBase();

    focusBase.classElementos = 'ss-venda-dados-principais';

    const { requisicaoAutorizacaoBase } = useRequisicaoAutorizacaoBase();

    telaBase.identificadorRecurso = 'VENDAS';
    const debounceCacheRegistro = 0;

    const state = reactive({
      notaFiscal: {} as INotaFiscal,
      formaPagamento: {} as IFormaPagamento,
      dadosPessoaMovimento: {} as IDTOPessoaMovimento,
      marcadores: [] as string[],
      registrosAtividade: [] as IDTONotaFiscalRegistroAtividade[],
      incidenciasCalculo: [] as INotaFiscalIncidenciaCalculo[],
      cacheRegistro: {} as ISalvamentoAutomatico,
      totalDetalhesPagamento: 0,
      telaMobile: false,
      editavel: false,
      cacheDisponivel: false,
      calculandoValores: false,
      chaveItens: 0,
      exibirAlteracaoEtapa: false,
      carregando: false,
      carregandoItens: false,
      adicionarPrimeiroItem: false,
      buscarDadosFiscaisRecalcular: false,
    });

    provide('VENDA_INCIDENCIA_CALCULO', state.incidenciasCalculo);
    async function limparTela() {
      state.adicionarPrimeiroItem = false;
      state.buscarDadosFiscaisRecalcular = false;
      state.incidenciasCalculo = [];
      state.notaFiscal = {} as INotaFiscal;
      state.notaFiscal.codigo = 0;
      state.notaFiscal.itens = [] as INotaFiscalItem[];
      state.notaFiscal = {} as INotaFiscal;
      state.notaFiscal.destinatario = {} as INotaFiscalDestinatario;
      state.notaFiscal.destinatario.codigoPessoa = 0;
      state.notaFiscal.pessoaIdentificada = true;

      state.notaFiscal.tipoOperacao = ETipoOperacao.Saida;
      state.notaFiscal.tipoFrete = ETipoFrete.SemOcorrenciaTransporte;
      state.notaFiscal.tipoEmissao = ETipoEmissao.EmissaoPropria;
      state.notaFiscal.modelo = '55';
      state.notaFiscal.dataEmissao = UtilitarioData.obterDataAtual();
      state.notaFiscal.dataEntradaSaida = UtilitarioData.obterDataAtual();
      state.notaFiscal.emitente = {} as INotaFiscalEmitente;
      state.notaFiscal.emitente.codigoPessoa = 0;
      state.notaFiscal.totalImpostos = {} as INotaFiscalImpostoTotal;
      state.notaFiscal.transporte = {} as INotaFiscalTransporte;
      state.notaFiscal.transporte.codigoTransportadora = 0;
      state.notaFiscal.transporte.volumes = [] as INotaFiscalTransporteVolume[];
      state.notaFiscal.totalDesconto = 0;
      state.notaFiscal.totalFrete = 0;
      state.notaFiscal.totalNotaFiscal = 0;
      state.notaFiscal.totalOutrasDespesas = 0;
      state.notaFiscal.totalProdutos = 0;
      state.notaFiscal.totalSeguro = 0;
      state.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;
      state.notaFiscal.anexos = [] as INotaFiscalAnexo[];
      state.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];

      state.marcadores = [] as string[];
      state.formaPagamento = {} as IFormaPagamento;
      state.registrosAtividade = [] as IDTONotaFiscalRegistroAtividade[];
      state.cacheDisponivel = false;
      state.cacheRegistro = {} as ISalvamentoAutomatico;
      state.cacheRegistro.codigo = 0;
      state.exibirAlteracaoEtapa = false;
      state.carregando = false;
      state.carregandoItens = false;
    }

    async function atualizarInformacoesPessoa() {
      state.dadosPessoaMovimento = await servicoPessoa.obterDadosMovimentacao(state.notaFiscal.destinatario.codigoPessoa, state.notaFiscal.codigoEmpresa);
      state.buscarDadosFiscaisRecalcular = true;
    }

    async function recuperarCacheRegistro() {
      if (UtilitarioGeral.valorValido(state.cacheRegistro.dados)) {
        telaBase.carregando = true;
        const vendaCache = JSON.parse(state.cacheRegistro.dados);
        if (UtilitarioGeral.objetoValido(vendaCache)) {
          state.notaFiscal = vendaCache;
          state.cacheDisponivel = false;
          state.cacheRegistro = {} as ISalvamentoAutomatico;
          state.cacheRegistro.codigo = 0;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
        telaBase.carregando = false;
      }
    }

    async function obterFormaPagamento() {
      if (UtilitarioGeral.validaCodigo(props.faturamento.codigoFormaPagamento)) {
        state.formaPagamento = await servicoFormaPagamento.obter(props.faturamento.codigoFormaPagamento);
      } else {
        state.formaPagamento = {} as IFormaPagamento;
      }
    }

    function atualizarTotalDetalhesPagamento() {
      state.totalDetalhesPagamento = 0;
      state.notaFiscal.detalhesPagamento.forEach((d) => {
        state.totalDetalhesPagamento += d.valorTotal;
      });
    }

    async function obterMarcadores() {
      state.notaFiscal.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    function preencherMarcadores() {
      const marcadores = [] as INotaFiscalMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as INotaFiscalMarcador;
        marcador.codigoNotaFiscal = state.notaFiscal.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    async function emitirNfe() {
      const retorno: IRetornoDFe = {} as IRetornoDFe;

      apresentarBarraProgresso('Aguarde por favor...');

      // retorno = await servicoVendas.emitirNfe(state.notaFiscal);

      ocultarBarraProgresso();
      if (retorno.status === EStatusDFe.Autorizado) {
        window.open(retorno.linkPdf, '_blank');
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }
    async function faturarVenda() {
      const retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      state.notaFiscal.marcadores = preencherMarcadores();
      state.notaFiscal.incidenciasCalculo = state.incidenciasCalculo;
      const indexSemCodigos:number[] = [];
      for (let index = 0; index < state.notaFiscal.itens.length; index += 1) {
        if (!UtilitarioGeral.validaCodigo(state.notaFiscal.itens[index].codigoProdutoDefinicao)) {
          indexSemCodigos.push(index);
        }
      }
      indexSemCodigos.forEach((index) => {
        state.notaFiscal.itens.splice(index, 1);
      });

      apresentarBarraProgresso('Aguarde por favor...');

      // retorno = await servicoVendas.faturarVenda(state.notaFiscal);

      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        state.carregandoItens = true;
        await preencherEmpresasDisponiveis();
        state.notaFiscal = await servicoFaturamento.obterDadosNotaFiscalFaturamento(props.faturamento.codigoNotaFiscal, 2);
        telaBase.carregando = false;
        state.carregandoItens = false;
      }
    });

    function obtemDescricaoIncidencia(identificador: string): string {
      if (identificador === 'OutrasDespesas') {
        return 'Outras Despesas';
      }

      return identificador;
    }

    async function atualizarAnexo(index: number) {
      if (state.notaFiscal.codigo === undefined || state.notaFiscal.codigo === 0) return;

      if (state.notaFiscal.anexos[index].codigo === undefined || state.notaFiscal.anexos[index].codigo === 0) {
        state.notaFiscal.anexos[index].codigoNotaFiscal = state.notaFiscal.codigo;
      }
    }

    async function excluirAnexo(codigoAnexo: number) {
      if (state.notaFiscal.codigo === undefined || state.notaFiscal.codigo === 0) return;

      await servicoNotaFiscal.excluirAnexo(codigoAnexo, state.notaFiscal.codigoEmpresa);
    }

    async function atualizarDetalhePagamento(index: number) {
      if (state.notaFiscal.codigo === undefined || state.notaFiscal.codigo === 0) return;
      atualizarTotalDetalhesPagamento();

      if (state.notaFiscal.detalhesPagamento[index].codigo === undefined || state.notaFiscal.detalhesPagamento[index].codigo === 0) {
        state.notaFiscal.detalhesPagamento[index].codigoNotaFiscal = state.notaFiscal.codigo;
      }
    }

    function obtemTituloMovimentacao():string {
      if (props.faturamento.movimentoComercial === 2) { return `da Devolução de Compras: ${props.faturamento.numero}`; }

      if (props.faturamento.movimentoComercial === 3) { return `da Venda: ${props.faturamento.numero}`; }

      if (props.faturamento.movimentoComercial === 4) { return `da Devolução de Vendas: ${props.faturamento.numero}`; }
      return '';
    }
    return {
      props,
      state,
      telaBase,
      modalBase,
      requisicaoAutorizacaoBase,
      ETipoValorMovimentacao,
      ETipoMovimentoComercial,
      ETipoRelacaoPessoa,
      UtilitarioGeral,
      UtilitarioMascara,
      apresentarRetornoRequisicao,
      atualizarTotalDetalhesPagamento,
      obterFormaPagamento,
      faturarVenda,
      obtemDescricaoIncidencia,
      atualizarAnexo,
      excluirAnexo,
      atualizarDetalhePagamento,
      emitirNfe,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      atualizarInformacoesPessoa,
      EStatusVenda,
      obtemTituloMovimentacao,
    };
  },
});
