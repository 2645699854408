import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  class: "ant-col",
  style: {"flex":"1 1 auto"}
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_11 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_12 = {
  key: 0,
  class: "ant-col",
  style: {"flex":"1 1 32x"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico_medida = _resolveComponent("campo-numerico-medida")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_a_form_item, {
                  label: "Qtd de Volumes",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      class: "ant-input",
                      style: {"text-align":"right"},
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedVolume.quantidadeVolume) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.campoAlterado && _ctx.campoAlterado(...args)))
                    }, null, 40, _hoisted_3), [
                      [_vModelText, _ctx.computedVolume.quantidadeVolume]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_form_item, {
                  label: "Espécie do Volume",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedVolume.especieVolume) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.campoAlterado && _ctx.campoAlterado(...args)))
                    }, null, 40, _hoisted_5), [
                      [_vModelText, _ctx.computedVolume.especieVolume]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_a_form_item, {
                  label: "Marca",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedVolume.marcaVolume) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.campoAlterado && _ctx.campoAlterado(...args)))
                    }, null, 40, _hoisted_7), [
                      [_vModelText, _ctx.computedVolume.marcaVolume]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_form_item, {
                  label: "Numeração",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedVolume.numeracaoVolume) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.campoAlterado && _ctx.campoAlterado(...args)))
                    }, null, 40, _hoisted_9), [
                      [_vModelText, _ctx.computedVolume.numeracaoVolume]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_a_form_item, {
                  label: "Peso Bruto",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico_medida, {
                      peso: true,
                      valor: _ctx.computedVolume.pesoBrutoVolume,
                      "onUpdate:valor": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedVolume.pesoBrutoVolume) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _ctx.campoAlterado
                    }, null, 8, ["valor", "disabled", "onBlur"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_a_form_item, {
                  label: "Peso Líquido",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico_medida, {
                      peso: true,
                      valor: _ctx.computedVolume.pesoLiquidoVolume,
                      "onUpdate:valor": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedVolume.pesoLiquidoVolume) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _ctx.campoAlterado
                    }, null, 8, ["valor", "disabled", "onBlur"])
                  ]),
                  _: 1
                })
              ]),
              (_ctx.props.editavel)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_a_form_item, {
                      label: " ",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", {
                          class: "ant-btn ant-btn-secondary",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.excluir()))
                        }, [
                          _createVNode(_component_icone, { nome: "excluir" })
                        ])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}