
import { defineComponent, reactive } from 'vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';

export default defineComponent({
  name: 'NotaFiscalHistorico',
  props: {
    registros: {
      type: Array as () => IDTONotaFiscalRegistroAtividade[],
      required: true,
    },
  },
  components: {
    MensagemSemDados,
  },
  setup(props) {
    const state = reactive({});

    return {
      props,
      state,
      UtilitarioData,
    };
  },
});
