import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      "not-found-content": null,
      value: _ctx.computedMarcadoresSelecionados,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedMarcadoresSelecionados) = $event)),
      mode: "tags",
      onSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pesquisarMarcador($event))),
      options: _ctx.state.listaMarcadores,
      placeholder: _ctx.props.placeholder,
      class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
      disabled: _ctx.props.disabled,
      onBlur: _ctx.blur
    }, null, 8, ["value", "options", "placeholder", "class", "disabled", "onBlur"])
  ]))
}