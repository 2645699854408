
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import { IDTOEstoqueProdutoDefinicao } from '@/models/DTO/Cadastros/Estoques/IDTOEstoqueProdutoDefinicao';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IDTOProdutoCaracteristica } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoCaracteristica';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoCodigo } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCodigo';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';
import { IProdutoImagem } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'DetalhesProdutoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    definicaoProduto: {
      type: Number,
      required: true,
    },
    empresa: {
      type: Number,
      required: true,
    },
    classeModal: {
      type: String,
      default: '',
    },
  },
  components: {
    Icone,
    Card,
  },
  emits: ['update:visivel'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoProduto = new ServicoProduto();
    const servicoEstoque = new ServicoEstoque();
    servicoProduto.requisicaoSistema();
    servicoEstoque.requisicaoSistema();

    const state = reactive({
      definicao: {} as IDTOProdutoDefinicao,
      estoques: [] as IDTOEstoqueProdutoDefinicao[],
      caracteristicas: [] as IDTOProdutoCaracteristica[],
      colunasEstoques: [] as IColumn[],
      colunasCaracteristicas: [] as IColumn[],
      colunasCodigos: [] as IColumn[],
    });

    function limparTela() {
      state.definicao = {} as IDTOProdutoDefinicao;
      state.definicao.imagens = [] as IProdutoImagem[];
      state.estoques = [] as IDTOEstoqueProdutoDefinicao[];
      state.caracteristicas = [] as IDTOProdutoCaracteristica[];
      state.colunasEstoques = [] as IColumn[];
      state.colunasCaracteristicas = [] as IColumn[];
      state.colunasCodigos = [] as IColumn[];
    }

    function preencherColunas() {
      state.colunasEstoques = [
        {
          title: 'Estoque', dataIndex: 'descricaoEstoque', key: 'DescricaoEstoque', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
        },
        {
          title: 'Quantidade Disponível', dataIndex: 'qtdDisponivel', key: 'QtdDisponivel', position: 1, visible: true, width: 100, ordering: true, align: 'right', ellipsis: true,
        },
      ];
      state.colunasCodigos = [
        {
          title: 'Tipo', dataIndex: 'tipoCodigo', key: 'TipoCodigo', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
        },
        {
          title: 'Código', dataIndex: 'codigoProduto', key: 'CodigoProduto', position: 1, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
        },
      ];
      state.colunasCaracteristicas = [
        {
          title: 'Tipo', dataIndex: 'tipo', key: 'Tipo', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
        },
        {
          title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 1, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
        },
        {
          title: 'Valor', dataIndex: 'valor', key: 'Valor', position: 2, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
        },
      ];
    }

    function obterDescricaoTipoCodigo(tipo: ETipoCodigo) {
      switch (tipo) {
        case ETipoCodigo.CodigoBarras:
          return 'Código de Barras';
        case ETipoCodigo.CodigoBalanca:
          return 'Código de Balança';
        case ETipoCodigo.Personalizado:
          return 'Personalizado';
        default:
          return 'Outros';
      }
    }

    function obterDescricaoTipoCaracteristica(tipo: ETipoCaracteristica) {
      switch (tipo) {
        case ETipoCaracteristica.TextoLivre:
          return 'Texto Livre';
        case ETipoCaracteristica.Numero:
          return 'Número';
        case ETipoCaracteristica.Decimal:
          return 'Decimal';
        case ETipoCaracteristica.ListaSimples:
          return 'Lista Simples';
        case ETipoCaracteristica.ListaSubItens:
          return 'Lista Subitens';
        case ETipoCaracteristica.Cor:
          return 'Cor';
        default:
          return 'Outros';
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        preencherColunas();
        state.definicao = await servicoProduto.obterDadosDetalhesDefinicao(props.definicaoProduto);
        if (!UtilitarioGeral.validaLista(state.definicao.imagens)) {
          state.definicao.imagens = [];
        }
        state.estoques = await servicoEstoque.obterEstoquesDefinicaoProduto(props.definicaoProduto, props.empresa);
        state.caracteristicas = await servicoProduto.obterCaracteristicasDefinicaoProduto(props.definicaoProduto, props.empresa);
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ETipoCaracteristica,
      UtilitarioGeral,
      UtilitarioMascara,
      obterDescricaoTipoCodigo,
      obterDescricaoTipoCaracteristica,
    };
  },
});
