
import { defineComponent } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import Icone from '../Icone.vue';

export default defineComponent({
  name: 'TotalizadorDecorado',
  props: {
    cor: {
      type: String,
      default: '',
    },
    iconeColorido: {
      type: Boolean,
      default: false,
    },
    icone: {
      type: String,
      default: '',
    },
    iconePequeno: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
    },
    monetario: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number,
      default: 0,
    },
    casasDecimais: {
      type: Number,
      default: 2,
    },
  },
  components: {
    Icone,
  },
  setup(props) {
    return {
      props,
      UtilitarioMascara,
    };
  },
});
