import { reactive, computed, ComputedRef } from 'vue';

export interface IFocusBase {
    classElementos: string;
}
export interface IRetornoFocusBase{
  focusBase: IFocusBase;
  elementosFocaveis: ComputedRef<HTMLCollectionOf<Element>>;
  findIndex(target:any):number;
  focusElemento(index:number):void;
  obtemIndexElemento(evento:any): number;
  proximoElemento(evento:any):void;
  elementoAnterior(evento:any):void;
  elementoAcima(evento:any, dataAttributeColuna:string):void;
  elementoAbaixo(evento:any, dataAttributeColuna:string):void;
}
export function useFocusBase():IRetornoFocusBase {
  const focusBase = reactive({
    classElementos: '',
  });

  const elementosFocaveis = computed(() => document.getElementsByClassName(focusBase.classElementos));

  function findIndex(target:any):number {
    return [].findIndex.call(elementosFocaveis.value, (e) => e === target);
  }

  function focusElemento(index:number) {
    const elemento = elementosFocaveis.value[index] as any;
    if (elemento) {
      if (elemento.classList.contains('ant-select')) {
        const inputSearch = elemento.getElementsByTagName('input');
        inputSearch[0].focus();
      } else if (elemento.classList.contains('ant-picker')) {
        const inputDate = elemento.getElementsByTagName('input');
        inputDate[0].focus();
      } else {
        elemento.focus();
      }
    }
  }

  function obtemIndexElemento(evento:any): number {
    let index = 0;
    index = findIndex(evento.target);

    if (index === -1) {
      index = findIndex(evento.target.parentElement.parentElement.parentElement);
      if (index === -1) {
        index = findIndex(evento.target.parentElement.parentElement);
      }
    }
    return index;
  }
  function proximoElemento(evento:any) {
    const index = obtemIndexElemento(evento);
    focusElemento(index + 1);
  }
  function elementoAnterior(evento:any) {
    const index = obtemIndexElemento(evento);
    focusElemento(index - 1);
  }

  function elementoAcima(evento:any, dataAttributeColuna:string) {
    let indexCampoAtual = obtemIndexElemento(evento);
    if (indexCampoAtual >= 0) {
      indexCampoAtual -= 1;
      let indexProximoFoco = 0;
      for (let index = indexCampoAtual; index > 0; index -= 1) {
        const element = elementosFocaveis.value[index] as any;
        if (element.dataset.coluna === dataAttributeColuna) {
          indexProximoFoco = index;
          break;
        }
      }
      focusElemento(indexProximoFoco);
    }
  }

  function elementoAbaixo(evento:any, dataAttributeColuna:string) {
    let indexProximoCampo = obtemIndexElemento(evento);
    indexProximoCampo += 1;
    let indexProximoFoco = 0;
    for (let index = indexProximoCampo; index < elementosFocaveis.value.length; index += 1) {
      const element = elementosFocaveis.value[index] as any;
      if (element.dataset.coluna === dataAttributeColuna) {
        indexProximoFoco = index;
        break;
      }
    }
    focusElemento(indexProximoFoco);
  }

  return {
    focusBase,
    elementosFocaveis,
    findIndex,
    focusElemento,
    obtemIndexElemento,
    proximoElemento,
    elementoAnterior,
    elementoAcima,
    elementoAbaixo,
  };
}
