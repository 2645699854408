
import {
  computed, defineComponent, ref,
} from 'vue';
import CampoCarregando from '@/core/components/Tela/CampoCarregando.vue';
import Icone from '@/core/components/Icone.vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';
import FormaPagamentoModal from '@/views/Cadastros/Financeiro/FormasPagamento/FormaPagamentoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';

export default defineComponent({
  name: 'SelecionarFormaPagamento',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    tipoMovimento: {
      type: Number,
      default: 0,
    },
    registrosEspecificos: {
      type: Array as () => number[],
      default: [] as number[],
    },
    apresentacaoPersonalizada: {
      type: Boolean,
      default: false,
    },
    apresentarCadastro: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Forma de Pagamento',
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Icone,
    CampoCarregando,
    FormaPagamentoModal,
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    const refSelecionar = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servico = new ServicoFormaPagamento();

    function preencheListaOpcoes(): void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
      if (props.apresentarCadastro) {
        selecionarBase.listaItensConsultaRapida.push({ codigo: 0, textoIdentificacao: 'Cadastrar', informacaoAdicional: '' } as IItemConsultaRapida);
      }
    }

    async function obterRegistroPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      selecionarBase.listaItensConsultaRapida = await servico.consultaRapidaAvancada(parametrosConsultaRapida, props.tipoMovimento);
      if (props.registrosEspecificos.length > 0) {
        selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((i) => props.registrosEspecificos.includes(i.codigo));
      }
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarRegistro(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;
      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        selecionarBase.listaItensConsultaRapida = await servico.consultaRapidaAvancada(parametrosConsultaRapida, props.tipoMovimento);
        if (props.registrosEspecificos.length > 0) {
          selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((i) => props.registrosEspecificos.includes(i.codigo));
        }
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    function obterRegistro(): any {
      obterRegistroPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterRegistro(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const itemConsulta = selecionarBase.listaItensConsultaRapida.find((c) => c.codigo === val);
        if (itemConsulta?.textoIdentificacao !== undefined && itemConsulta?.textoIdentificacao !== '') {
          computedDescricaoSelecionado.value = itemConsulta?.textoIdentificacao;
        }
      },
    });

    function obterRegistros(): number[] {
      obterRegistroPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterRegistros(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionar.value) {
        refSelecionar.value.focus();
      }
    }

    function cadastrar() {
      computedCodigoSelecionado.value = 0;
      selecionarBase.operacaoCadastro.tipoPermissaoDados = EPermissaoDados.Incluir;
      selecionarBase.operacaoCadastro.listaPermissoesDados = [];
      selecionarBase.operacaoCadastro.codigoRegistro = 0;
      selecionarBase.operacaoCadastro.empresaSelecionada = props.empresas[0];
      selecionarBase.operacaoCadastro.codigoRegistroDuplicar = 0;
      selecionarBase.operacaoCadastro.codigoRegistroPai = 0;
      selecionarBase.operacaoCadastro.requisicaoSistema = true;
      selecionarBase.apresentarCadastro = true;
    }

    return {
      props,
      selecionarBase,
      refSelecionar,
      pesquisarRegistro,
      aguardarConclusaoCarregamento,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedDescricaoSelecionado,
      change,
      focus,
      UtilitarioGeral,
      cadastrar,
    };
  },
});
