
import {
  defineComponent, reactive, computed, watch, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarCentroCusto from '@/components/Cadastros/PlanosContas/SelecionarCentroCusto.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { INotaFiscalDetalhePagamentoCentroCusto } from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

export default defineComponent({
  name: 'NotaFiscalDetalhePagamentoCentroCusto',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    valor: {
      type: Number,
      required: true,
    },
    codigoRegistro: {
      type: Number,
      required: true,
    },
    centrosCusto: {
      type: Array as () => INotaFiscalDetalhePagamentoCentroCusto[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Icone,
    CampoNumerico,
    SelecionarCentroCusto,
    MensagemSemDados,
  },
  emits: ['update:centrosCusto'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const state = reactive({
      centroCustoSelecionado: 0,
      descricaoCentroCustoSelecionado: '',
      proporcao: 100.0,
      telaMobile: false,
    });

    const computedCentrosCusto = computed({
      get: () => props.centrosCusto,
      set: (centrosCusto: INotaFiscalDetalhePagamentoCentroCusto[]) => {
        emit('update:centrosCusto', centrosCusto);
      },
    });

    function obterRestoProporcao() {
      if (!UtilitarioGeral.validaLista(props.centrosCusto)) {
        return 100;
      }

      let resto = 100;
      props.centrosCusto.forEach((c) => {
        resto -= c.proporcao;
      });
      return resto;
    }

    function obterValorProporcao(proporcao: number) {
      if (props.valor > 0) {
        return (props.valor / 100) * proporcao;
      }
      return 0;
    }

    function limparCampos() {
      state.centroCustoSelecionado = 0;
      state.descricaoCentroCustoSelecionado = '';
      state.proporcao = obterRestoProporcao();
    }

    function adicionarCentroCusto() {
      if (state.centroCustoSelecionado === undefined || state.centroCustoSelecionado === 0) {
        apresentarMensagemAlerta('Nenhum centro de custo selecionado!');
        return;
      }
      const centroCustoExistente = computedCentrosCusto.value.find((c) => c.codigoCentroCusto === state.centroCustoSelecionado);
      if (UtilitarioGeral.valorValido(centroCustoExistente)) {
        apresentarMensagemAlerta('O centro de custo selecionado já foi adicionado!');
        return;
      }

      const centroCusto = {} as INotaFiscalDetalhePagamentoCentroCusto;
      centroCusto.codigoNotaFiscalDetalhePagamento = props.codigoRegistro;
      centroCusto.codigoCentroCusto = state.centroCustoSelecionado;
      centroCusto.descricaoCentroCusto = state.descricaoCentroCustoSelecionado;
      centroCusto.proporcao = state.proporcao;
      centroCusto.ordem = computedCentrosCusto.value.length + 1;
      computedCentrosCusto.value.push(centroCusto);
      limparCampos();
    }

    function removerCentroCusto(index: number) {
      computedCentrosCusto.value.splice(index, 1);
      state.proporcao = obterRestoProporcao();
    }

    watch(async () => props.visivel, async () => {
      limparCampos();
    });

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    return {
      props,
      state,
      computedCentrosCusto,
      obterValorProporcao,
      adicionarCentroCusto,
      removerCentroCusto,
      obterRestoProporcao,
      limparCampos,
    };
  },
});
