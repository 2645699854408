
import {
  Ref,
  computed, defineComponent, inject, onBeforeMount, reactive, watch,
} from 'vue';
import { useMagicKeys } from '@vueuse/core';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarProdutoMovimento from '@/components/Cadastros/Produtos/SelecionarProdutoMovimento.vue';
import SelecionarUnidadeProdutoMovimento from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidadeProdutoMovimento.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { EOrigemValorItem } from '@/models/Enumeradores/Compartilhados/EOrigemValorItem';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useFocusBase } from '@/core/composables/FocusBase';
import { IVendaItem } from '@/models/Entidades/Vendas/IVenda';
import {
  INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalItemImposto, INotaFiscalItemImpostoIcms, INotaFiscalItemImpostoIpi, INotaFiscalItemImpostoPis, INotaFiscalItemImpostoCofins, INotaFiscalItemImpostoSubstituicaoTributaria, INotaFiscalItemImpostoImportacao, INotaFiscalItemMedicamento, INotaFiscalItemCombustivel, INotaFiscalItemEstoque,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import ServicoMovimentoEstoque from '@/servicos/Estoques/ServicoMovimentoEstoque';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { IEtapaProcessoVendas } from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import DetalhesProdutoModal from '@/components/Cadastros/Produtos/DetalhesProdutoModal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';

export default defineComponent({
  name: 'FaturamentoItens',
  props: {
    dadosPessoaMovimento: {
      type: Object as () => IDTOPessoaMovimento,
      required: true,
    },
    atualizarValores: {
      type: Boolean,
      required: true,
    },
    totalVenda: {
      type: Number,
      required: true,
    },
    incidencias: {
      type: Array as () => INotaFiscalIncidenciaCalculo[],
      required: true,
    },
    itens: {
      type: Array as () => INotaFiscalItem[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    chaveItens: {
      type: Number,
      default: 0,
    },
    carregando: {
      type: Boolean,
      default: false,
    },
    adicionarPrimeiroItem: {
      type: Boolean,
      default: false,
    },
    buscarDadosFiscaisRecalcular: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    CampoNumerico,
    SelecionarProdutoMovimento,
    SelecionarUnidadeProdutoMovimento,
    MensagemSemDados,
    DetalhesProdutoModal,
  },
  emits: ['update:itens', 'update:carregando', 'update:buscarDadosFiscaisRecalcular', 'itemAlterado'],
  setup(props, { emit }) {
    const debounce = 0;
    const debounceRenderizarGrade = 0;
    const empresa = inject('VENDAS_EMPRESA') as Ref<number>;
    const etapa = inject('VENDAS_ETAPA_LANCAMENTO') as Ref<IEtapaProcessoVendas>;
    const servicoVendas = new ServicoVendas();
    const servicoMovimentoEstoque = new ServicoMovimentoEstoque();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    servicoCenarioFiscal.requisicaoSistema();

    const keys = useMagicKeys();
    const shiftN = keys['Shift+N'];
    const { apresentarMensagemAlerta } = useTelaBase();
    const {
      focusBase, elementosFocaveis, obtemIndexElemento, focusElemento, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-venda-itens';

    const state = reactive({
      colunasGrade: [] as IColumn[],
      codigoDefinicaoSelecionada: 0,
      indexItemSelecionado: -1,
      itemSelecionado: {} as INotaFiscalItem,
      indexDetalhesPreco: -1,
      indexDetalhesEstoques: -1,
      existeProximo: false,
      existeAnterior: false,
      exibirDetalhesItem: false,
      exibirDetalhesProduto: false,
      telaMobile: false,
    });
    function obterItens():any {
      return props.itens;
    }
    const computedItens = computed({
      get: () => obterItens(),
      set: (val: INotaFiscalItem[]) => {
        emit('update:itens', val);
      },
    });

    const computedCarregando = computed({
      get: () => props.carregando,
      set: (val: boolean) => {
        emit('update:carregando', val);
      },
    });

    const computedBuscarDadosFiscaisRecalcular = computed({
      get: () => props.buscarDadosFiscaisRecalcular,
      set: (val: boolean) => {
        emit('update:buscarDadosFiscaisRecalcular', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 1, visible: true, width: 290, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Unidade', dataIndex: 'unidade', key: 'Unidade', position: 3, visible: true, width: 35, ordering: false, align: 'center', ellipsis: true,
          },
          {
            title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 4, visible: true, ordering: false, align: 'right', width: 50, ellipsis: true,
          },
          {
            title: 'Valor Unitário', dataIndex: 'valorUnitario', key: 'ValorUnitario', position: 5, visible: true, ordering: false, align: 'right', width: 60, ellipsis: true,
          },
          {
            title: 'ICMS', dataIndex: 'icms', key: 'Icms', position: 6, visible: true, align: 'center', width: 30, ellipsis: true,
          },
          {
            title: 'IPI', dataIndex: 'ipi', key: 'Ipi', position: 7, visible: true, ordering: false, align: 'center', width: 30, ellipsis: true,
          },
          {
            title: 'Desconto', dataIndex: 'desconto', key: 'Desconto', position: 8, visible: true, ordering: false, align: 'right', width: 40, ellipsis: true,
          },
          {
            title: 'Valor Total', dataIndex: 'total', key: 'Total', position: 9, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 0, visible: true, width: 30, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    function validarExisteProximoItem() {
      state.existeProximo = state.indexItemSelecionado < computedItens.value.length - 1;
    }

    function validarExisteItemAnterior() {
      state.existeAnterior = state.indexItemSelecionado > 0;
    }

    function editarItem(index: number) {
      state.indexItemSelecionado = index;
      state.itemSelecionado = computedItens.value[index];
      validarExisteProximoItem();
      state.exibirDetalhesItem = true;
    }

    function exibirDetalhesProduto(index: number) {
      if (UtilitarioGeral.validaCodigo(computedItens.value[index].codigoProdutoDefinicao)) {
        state.codigoDefinicaoSelecionada = computedItens.value[index].codigoProdutoDefinicao;
        state.indexItemSelecionado = index;
        state.itemSelecionado = computedItens.value[index];
        state.exibirDetalhesProduto = true;
      } else {
        apresentarMensagemAlerta('É necessário um produto para utilizar este recurso.');
      }
    }

    function proximoItem() {
      editarItem(state.indexItemSelecionado + 1);
    }

    function itemAnterior() {
      editarItem(state.indexItemSelecionado - 1);
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
    });

    return {
      props,
      state,
      computedItens,
      empresa,
      UtilitarioMascara,
      UtilitarioMatematico,
      proximoItem,
      itemAnterior,
      computedCarregando,
      editarItem,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      elementoAcima,
      elementoAbaixo,
      exibirDetalhesProduto,
    };
  },
});
