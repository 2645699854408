export enum ETipoValorMovimentacao {
  Frete = 1,
  Seguro = 2,
  OutrasDespesas = 3,
  Desconto = 4,
  Icms = 5,
  IcmsSt = 6,
  Ipi = 7,
  Pis = 8,
  Cofins = 9,
  Importacao = 10,
  ValorTotal = 11,
}
