
import {
  defineComponent, reactive, onBeforeMount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IParametrosConsultaVendaFaturamento } from '@/models/ParametrosRequisicao/Vendas/IParametrosConsultaVendaFaturamento';
import { IDTOFaturamentoTotalizadorStatus } from '@/models/DTO/Fiscal/IDTOFaturamentoTotalizadorStatus';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import FaturamentoVendasModal from './FaturamentoVendasModal.vue';
import { IVenda } from '@/models/Entidades/Vendas/IVenda';
import { useModalBase } from '@/core/composables/ModalBase';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOFaturamento } from '@/models/DTO/Fiscal/IDTOFaturamento';
import ServicoFaturamento from '@/servicos/Fiscal/ServicoFaturamento';
import { EStatusDFe } from '@/core/models/Enumeradores/EStatusDFe';
import { EStatusNotaFiscal } from '@/models/Enumeradores/Compartilhados/EStatusNotaFiscal';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export enum EStatusFiltroFaturamento{
  AguardandoFaturamento = 1,
  Faturados = 2,
  Cancelados = 3
}

export default defineComponent({
  name: 'CentralFaturamentos',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    TotalizadorDecorado,
    BuscaGeral,
    SelecionarFiltroPeriodoMovimentoComercial,
    FaturamentoVendasModal,
    RequisicaoModal,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, adicionarAtalho, removerAtalho, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();

    const { gradeBase, defineAlturaScroll } = useGradeBase();
    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    const servicoFaturamento = new ServicoFaturamento();
    const state = reactive({
      empresaSelecionada: 0,
      codigoVendaSelecionada: 0,
      apresentarModal: false,
      faturamentoSelecionado: {} as IDTOFaturamento,
      faturamentos: [] as IDTOFaturamento[],
      totalizadores: [] as IDTOFaturamentoTotalizadorStatus[],
      filtros: {} as IParametrosConsultaVendaFaturamento,
      buscaAvancada: {} as IBuscaAvancada,
      filtroPeriodo: 4,
      exibirColunaDocumentoFiscal: false,
      chaveFaturamentos: 1,
      dataAtual: '',
    });

    state.filtros.empresas = [storeSistema.getters.codigoEmpresaOperacao()];
    state.filtros.status = 1;
    state.filtros.movimentosComerciais = [];
    state.filtros.valorBuscaRapida = '';
    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        // Colunas - Vendas
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'Codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'Assinatura', position: 2, visible: true,
          },
        ];
      } else {
        // Colunas - Vendas
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Origem', dataIndex: 'descricaoMovimentoComercial', key: 'DescricaoMovimentoComercial', position: 2, visible: true, ellipsis: true, width: 30,
          },
          {
            title: 'Data', dataIndex: 'dataMovimentacao', key: 'DataMovimentacao', position: 3, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 30, align: 'center',
          },
          {
            title: 'Número', dataIndex: 'numero', key: 'Numero', position: 4, visible: true, width: 30, align: 'right',
          },
          {
            title: 'Pessoa', dataIndex: 'pessoa', key: 'Pessoa', position: 5, visible: true, ellipsis: true, width: 90,
          },
          {
            title: 'Valor R$', dataIndex: 'total', key: 'Total', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 45,
          },
          {
            title: 'Documento Fiscal', dataIndex: 'documentoFiscal', key: 'DocumentoFiscal', position: 7, visible: state.exibirColunaDocumentoFiscal, width: 60, align: 'left',
          },
          {
            title: 'Status', dataIndex: 'statusDescricao', key: 'Status', position: 8, visible: true, align: 'center', width: 20, ellipsis: true,
          },
          {
            title: 'Responsável', dataIndex: 'nomeColaborador', key: 'NomeColaborador', position: 9, visible: true, ellipsis: true, width: 90,
          },
          {
            title: 'Ações', key: 'acoes', position: 10, visible: true, fixed: 'right', width: 30, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
          },
        ];
      }
    }

    function obterFiltros() {
      switch (state.filtros.periodo) {
        case EFiltroPeriodoMovimentoComercial.Hoje:
          state.filtros.dataInicial = state.dataAtual;
          state.filtros.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.MesAtual:
          state.filtros.dataInicial = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.filtros.dataFinal = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.AnoAtual:
          state.filtros.dataInicial = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.filtros.dataFinal = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.SemanaAtual:
          state.filtros.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.filtros.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos15Dias:
          state.filtros.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.filtros.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos30Dias:
          state.filtros.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.filtros.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos60Dias:
          state.filtros.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.filtros.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos90dias:
          state.filtros.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.filtros.dataFinal = state.dataAtual;
          break;

        default:
      }
    }

    async function buscarFaturamentos() {
      state.filtros.empresas = [storeSistema.getters.codigoEmpresaOperacao()];
      if (state.filtros.status !== EStatusFiltroFaturamento.AguardandoFaturamento) {
        state.exibirColunaDocumentoFiscal = true;
      }

      if (state.filtros.periodo === EFiltroPeriodoMovimentoComercial.Personalizado && !UtilitarioGeral.valorValido(state.filtros.dataInicial)) {
        apresentarMensagemAlerta('A data inicial deve ser informada antes de buscar as movimentações!');
        return;
      }

      if (state.filtros.periodo === EFiltroPeriodoMovimentoComercial.Personalizado && !UtilitarioGeral.valorValido(state.filtros.dataFinal)) {
        apresentarMensagemAlerta('A data final deve ser informada antes de buscar as movimentações!');
        return;
      }
      obterFiltros();

      preencherColunas();
      state.faturamentos = await servicoFaturamento.obterFaturamentos(state.filtros);
      state.chaveFaturamentos += 1;
    }

    async function buscarFaturamentosPorStatus(status: number) {
      state.filtros.status = status;
      await buscarFaturamentos();
    }

    async function periodoAlterado() {
      if (state.filtroPeriodo !== EFiltroPeriodoMovimentoComercial.Personalizado) {
        await buscarFaturamentos();
      }
    }

    function obtemValorTotalizador(status: EStatusFiltroFaturamento) {
      if (UtilitarioGeral.validaLista(state.totalizadores)) {
        const totalizador = state.totalizadores.find((c) => c.status === status);
        if (totalizador !== undefined) {
          return totalizador.total;
        }
      }
      return 0;
    }

    function obterCorStatus(movimentoComercial: number, status:number): string {
      let cor = '';

      // Devoluções Compras
      if (movimentoComercial === 2) {
        switch (status) {
          case 2:
            cor += '#ffeb3b';
            break;

          case 3:
            cor += '#87e22d';
            break;

          case 4:
            cor += '#f44336';
            break;

          default:
            break;
        }
      }

      // Vendas
      if (movimentoComercial === 3) {
        switch (status) {
          case 4:
            cor += '#ffeb3b';
            break;

          case 5:
          case 6:
            cor += '#87e22d';
            break;

          case 7:
            cor += '#f44336';
            break;

          default:
            break;
        }
      }

      return cor;
    }

    function exibirEmitirDocumentoFiscal(movimentoComercial: number, status:number, statusNotaFiscal:number): boolean {
      // Devoluções Compras
      if (movimentoComercial === 2 && status === 3 && statusNotaFiscal === EStatusNotaFiscal.Pendente) {
        return true;
      }

      // Vendas
      if (movimentoComercial === 3 && status === 5 && statusNotaFiscal === EStatusNotaFiscal.Pendente) {
        return true;
      }

      return false;
    }

    function exibirFaturamento(movimentoComercial: number, status:number): boolean {
      // Devoluções Compras
      if (movimentoComercial === 2 && status === 2) {
        return true;
      }

      // Vendas
      if (movimentoComercial === 3 && status === 4) {
        return true;
      }

      return false;
    }

    function visualizarFaturamento(faturamento: IDTOFaturamento) {
      state.faturamentoSelecionado = faturamento;
      state.apresentarModal = true;
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      telaBase.identificadorRecurso = 'VENDAS';
      // telaBase.identificadorPermissao = 'AUT_CONTROLE_ASSINATURAS';

      await preencherEmpresasDisponiveis();
      preencherColunas();
      state.dataAtual = await servicoSistema.obterDataAtual();
      state.dataAtual = UtilitarioData.converterDataJson(state.dataAtual);

      // await obterPermissoes(ETipoPermissao.Autorizacoes);
      state.totalizadores = await servicoFaturamento.obterTotalizadores(storeSistema.getters.codigoEmpresaOperacao());
      await buscarFaturamentosPorStatus(EStatusFiltroFaturamento.AguardandoFaturamento);
      telaBase.carregando = false;
    });

    async function atualizarInformacoesTela() {
      state.totalizadores = await servicoFaturamento.obterTotalizadores(storeSistema.getters.codigoEmpresaOperacao());
      await buscarFaturamentos();
    }

    function obtemTituloMovimentacao(faturamento:IDTOFaturamento):string {
      if (faturamento.movimentoComercial === 2) { return `da Devolução de Compras N: ${faturamento.numero}`; }

      if (faturamento.movimentoComercial === 3) { return `da Venda N: ${faturamento.numero}`; }

      if (faturamento.movimentoComercial === 4) { return `da Devolução de Vendas N: ${faturamento.numero}`; }
      return '';
    }

    async function faturar(faturamento:IDTOFaturamento) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      Modal.confirm({
        title: `Você confirma o faturamento ${obtemTituloMovimentacao(faturamento)}?`,
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => {
          apresentarBarraProgresso('Aguarde por favor...');

          retorno = await servicoFaturamento.faturar(faturamento, 2);

          ocultarBarraProgresso();
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            emit('confirmacao');
            apresentarMensagemSucesso(retorno.mensagem);
            await atualizarInformacoesTela();
            modalBase.computedVisivel = false;
          } else {
            apresentarRetornoRequisicao(retorno);
          }
        },
      });
    }

    async function emitirNotaFiscal(faturamento:IDTOFaturamento) {
      Modal.confirm({
        title: `Você deseja emitir a Nota Fiscal ${obtemTituloMovimentacao(faturamento)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => {
          apresentarBarraProgresso('Aguarde, comunicando com a SEFAZ...');
          const retornoDFe = await servicoFaturamento.emitirDocumentoFiscal(faturamento, 2);
          ocultarBarraProgresso();
          if (retornoDFe.status === EStatusDFe.Autorizado) {
            emit('confirmacao');
            apresentarMensagemSucesso('NF-e Emitida com sucesso!');
            window.open(retornoDFe.linkPdf, '_blank');
          } else {
            apresentarMensagemAlerta(retornoDFe.mensagem);
          }
        },
      });
    }
    return {
      telaBase,
      gradeBase,
      modalBase,
      storeSistema,
      state,
      EStatusVenda,
      ECustomRenderRow,
      EFiltroPeriodoMovimentoComercial,
      UtilitarioData,
      defineAlturaScroll,
      adicionarAtalho,
      removerAtalho,
      buscarFaturamentos,
      buscarFaturamentosPorStatus,
      obtemValorTotalizador,
      obterCorStatus,
      periodoAlterado,
      atualizarInformacoesTela,
      UtilitarioMascara,
      SelecionarDataInicialFinal,
      visualizarFaturamento,
      faturar,
      emitirNotaFiscal,
      EStatusFiltroFaturamento,
      exibirFaturamento,
      exibirEmitirDocumentoFiscal,
    };
  },
});
