import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = {
  key: 0,
  class: "acoes-item"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 9,
  class: "ss-table-apresentacao-mobile"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  class: "acoes-item",
  style: {"margin-top":"2px"}
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_produto_movimento = _resolveComponent("selecionar-produto-movimento")!
  const _component_selecionar_unidade_produto_movimento = _resolveComponent("selecionar-unidade-produto-movimento")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_detalhes_produto_modal = _resolveComponent("detalhes-produto-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_component_a_table, {
          key: _ctx.props.chaveItens,
          loading: _ctx.props.carregando,
          columns: _ctx.state.colunasGrade.filter(item => item.visible),
          "data-source": _ctx.props.itens,
          rowKey: "codigo",
          pagination: false,
          size: "small",
          rowClassName: "ss-table-campos",
          bordered: ""
        }, {
          emptyText: _withCtx(() => [
            _createVNode(_component_mensagem_sem_dados, { mensagem: "Nenhum item adicionado ainda" })
          ]),
          bodyCell: _withCtx(({ column, record, index }) => [
            (column.key === 'acoes')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("a", {
                    class: "visualizar",
                    title: "Detalhes do Produto",
                    onClick: ($event: any) => (_ctx.exibirDetalhesProduto(index))
                  }, [
                    _createVNode(_component_icone, { nome: "informacoes-fp" })
                  ], 8, _hoisted_4)
                ]))
              : (column.key === 'Produto')
                ? (_openBlock(), _createBlock(_component_selecionar_produto_movimento, {
                    key: 1,
                    classCss: "hidden ss-campo-selecao-table ss-venda-itens",
                    classGroupCss: "ss-campo-selecao-table",
                    title: column.title,
                    dataAttributeColuna: column.key,
                    empresas: [_ctx.empresa],
                    codigoSelecionado: record.codigoProdutoDefinicao,
                    "onUpdate:codigoSelecionado": ($event: any) => ((record.codigoProdutoDefinicao) = $event),
                    descricao: record.descricao,
                    "onUpdate:descricao": ($event: any) => ((record.descricao) = $event),
                    codigoUnidadePrincipal: record.codigoUnidadePrincipal,
                    "onUpdate:codigoUnidadePrincipal": ($event: any) => ((record.codigoUnidadePrincipal) = $event),
                    fatorUnidadePrincipal: record.fatorUnidadePrincipal,
                    "onUpdate:fatorUnidadePrincipal": ($event: any) => ((record.fatorUnidadePrincipal) = $event),
                    codigoInterno: record.codigoProduto,
                    "onUpdate:codigoInterno": ($event: any) => ((record.codigoProduto) = $event),
                    codigoBarras: record.codigoBarras,
                    "onUpdate:codigoBarras": ($event: any) => ((record.codigoBarras) = $event),
                    origemMercadoria: record.impostos.origemMercadoria,
                    "onUpdate:origemMercadoria": ($event: any) => ((record.impostos.origemMercadoria) = $event),
                    codigoNcm: record.impostos.codigoNcm,
                    "onUpdate:codigoNcm": ($event: any) => ((record.impostos.codigoNcm) = $event),
                    ncm: record.impostos.ncm,
                    "onUpdate:ncm": ($event: any) => ((record.impostos.ncm) = $event),
                    buscarDadosAtualizados: _ctx.props.atualizarValores,
                    disabled: !_ctx.props.editavel,
                    limparSelecao: false,
                    onKeydown: [
                      _withKeys(_withModifiers(($event: any) => (_ctx.exibirDetalhesProduto(index)), ["shift","exact","prevent"]), ["d"]),
                      _withKeys(_withModifiers(($event: any) => (_ctx.editarItem(index)), ["shift","exact","prevent"]), ["e"]),
                      _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                      _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                      _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent","shift"]), ["tab"]))
                    ]
                  }, null, 8, ["title", "dataAttributeColuna", "empresas", "codigoSelecionado", "onUpdate:codigoSelecionado", "descricao", "onUpdate:descricao", "codigoUnidadePrincipal", "onUpdate:codigoUnidadePrincipal", "fatorUnidadePrincipal", "onUpdate:fatorUnidadePrincipal", "codigoInterno", "onUpdate:codigoInterno", "codigoBarras", "onUpdate:codigoBarras", "origemMercadoria", "onUpdate:origemMercadoria", "codigoNcm", "onUpdate:codigoNcm", "ncm", "onUpdate:ncm", "buscarDadosAtualizados", "disabled", "onKeydown"]))
                : (column.key === 'Unidade')
                  ? (_openBlock(), _createBlock(_component_selecionar_unidade_produto_movimento, {
                      classCss: "hidden ss-campo-selecao-table ss-venda-itens",
                      classGroupCss: "ss-campo-selecao-table",
                      apenasUnidadePrincipal: false,
                      dataAttributeColuna: column.key,
                      title: record.descricaoUnidade,
                      codigoProdutoDefinicao: record.codigoProdutoDefinicao,
                      editavel: _ctx.props.editavel,
                      codigoUnidadeSelecionada: record.codigoUnidade,
                      "onUpdate:codigoUnidadeSelecionada": ($event: any) => ((record.codigoUnidade) = $event),
                      descricao: record.descricaoUnidade,
                      "onUpdate:descricao": ($event: any) => ((record.descricaoUnidade) = $event),
                      sigla: record.siglaUnidade,
                      "onUpdate:sigla": ($event: any) => ((record.siglaUnidade) = $event),
                      fracionar: record.fracionar,
                      "onUpdate:fracionar": ($event: any) => ((record.fracionar) = $event),
                      casasDecimais: record.casasDecimaisUnidade,
                      "onUpdate:casasDecimais": ($event: any) => ((record.casasDecimaisUnidade) = $event),
                      fatorConversao: record.fatorConversao,
                      "onUpdate:fatorConversao": ($event: any) => ((record.fatorConversao) = $event),
                      percentualFormacaoPreco: record.percentualFormacaoPreco,
                      "onUpdate:percentualFormacaoPreco": ($event: any) => ((record.percentualFormacaoPreco) = $event),
                      apresentarApenasSigla: true,
                      disabled: (!_ctx.props.editavel || (record.codigoProdutoDefinicao === 0)),
                      key: ('UNIDADE' + record.codigoProdutoDefinicao + _ctx.props.chaveItens + index),
                      buscarDadosAtualizados: _ctx.props.atualizarValores,
                      onKeydown: [
                        _withKeys(_withModifiers(($event: any) => (_ctx.exibirDetalhesProduto(index)), ["shift","exact","prevent"]), ["d"]),
                        _withKeys(_withModifiers(($event: any) => (_ctx.editarItem(index)), ["shift","exact","prevent"]), ["e"]),
                        _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                        _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                        _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent","shift"]), ["tab"])),
                        _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                        _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                      ]
                    }, null, 8, ["dataAttributeColuna", "title", "codigoProdutoDefinicao", "editavel", "codigoUnidadeSelecionada", "onUpdate:codigoUnidadeSelecionada", "descricao", "onUpdate:descricao", "sigla", "onUpdate:sigla", "fracionar", "onUpdate:fracionar", "casasDecimais", "onUpdate:casasDecimais", "fatorConversao", "onUpdate:fatorConversao", "percentualFormacaoPreco", "onUpdate:percentualFormacaoPreco", "disabled", "buscarDadosAtualizados", "onKeydown"]))
                  : (column.key === 'Quantidade')
                    ? (_openBlock(), _createBlock(_component_campo_numerico, {
                        dataAttributeColuna: column.key,
                        title: column.title,
                        classCss: "hidden ss-campo-numero-table ss-venda-itens",
                        classGroupCss: "hidden ss-campo-numero-table ant-input",
                        valor: record.quantidade,
                        "onUpdate:valor": ($event: any) => ((record.quantidade) = $event),
                        emDigitacao: record.emDigitacao,
                        "onUpdate:emDigitacao": ($event: any) => ((record.emDigitacao) = $event),
                        quantidadeCasasDecimais: record.casasDecimaisUnidade,
                        disabled: !_ctx.props.editavel,
                        key: ('QTDE' + record.codigoProdutoDefinicao + _ctx.props.chaveItens + index),
                        onFocus: ($event: any) => (_ctx.state.indexDetalhesEstoques = index),
                        onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.state.indexDetalhesEstoques = -1)),
                        onKeydown: [
                          _withKeys(_withModifiers(($event: any) => (_ctx.exibirDetalhesProduto(index)), ["shift","exact","prevent"]), ["d"]),
                          _withKeys(_withModifiers(($event: any) => (_ctx.editarItem(index)), ["shift","exact","prevent"]), ["e"]),
                          _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                          _cache[8] || (_cache[8] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                          _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent","shift"]), ["tab"])),
                          _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                          _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                        ]
                      }, null, 8, ["dataAttributeColuna", "title", "valor", "onUpdate:valor", "emDigitacao", "onUpdate:emDigitacao", "quantidadeCasasDecimais", "disabled", "onFocus", "onKeydown"]))
                    : (column.key === 'ValorUnitario')
                      ? (_openBlock(), _createBlock(_component_campo_numerico, {
                          dataAttributeColuna: column.key,
                          title: column.title,
                          monetario: true,
                          classGroupCss: "hidden ss-campo-numero-table",
                          classCss: "hidden ss-campo-numero-table ss-venda-itens",
                          valor: record.precoUnitario,
                          "onUpdate:valor": ($event: any) => ((record.precoUnitario) = $event),
                          emDigitacao: record.emDigitacao,
                          "onUpdate:emDigitacao": ($event: any) => ((record.emDigitacao) = $event),
                          disabled: !_ctx.props.editavel,
                          quantidadeCasasDecimais: record.casasDecimaisPrecoUnitario,
                          key: ('VUNIT' + record.codigoProdutoDefinicao + _ctx.props.chaveItens + index),
                          onFocus: ($event: any) => (_ctx.state.indexDetalhesPreco = index),
                          onBlur: _cache[10] || (_cache[10] = ($event: any) => (_ctx.state.indexDetalhesPreco = -1)),
                          onKeydown: [
                            _withKeys(_withModifiers(($event: any) => (_ctx.exibirDetalhesProduto(index)), ["shift","exact","prevent"]), ["d"]),
                            _withKeys(_withModifiers(($event: any) => (_ctx.editarItem(index)), ["shift","exact","prevent"]), ["e"]),
                            _cache[11] || (_cache[11] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                            _cache[12] || (_cache[12] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                            _cache[13] || (_cache[13] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent","shift"]), ["tab"])),
                            _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                            _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                          ]
                        }, null, 8, ["dataAttributeColuna", "title", "valor", "onUpdate:valor", "emDigitacao", "onUpdate:emDigitacao", "disabled", "quantidadeCasasDecimais", "onFocus", "onKeydown"]))
                      : (column.key === 'Icms')
                        ? (_openBlock(), _createBlock(_component_campo_numerico, {
                            key: 5,
                            classCss: "hidden ss-campo-numero-table",
                            classGroupCss: "hidden ss-campo-numero-table",
                            title: column.title,
                            dataAttributeColuna: column.key,
                            valor: record.impostos.icms.aliquotaIcms,
                            "onUpdate:valor": ($event: any) => ((record.impostos.icms.aliquotaIcms) = $event),
                            percentual: true,
                            disabled: true
                          }, null, 8, ["title", "dataAttributeColuna", "valor", "onUpdate:valor"]))
                        : (column.key === 'Ipi')
                          ? (_openBlock(), _createBlock(_component_campo_numerico, {
                              key: 6,
                              classCss: "hidden ss-campo-numero-table",
                              classGroupCss: "hidden ss-campo-numero-table",
                              title: column.title,
                              dataAttributeColuna: column.key,
                              valor: record.impostos.ipi.aliquotaIpi,
                              "onUpdate:valor": ($event: any) => ((record.impostos.ipi.aliquotaIpi) = $event),
                              percentual: true,
                              disabled: true
                            }, null, 8, ["title", "dataAttributeColuna", "valor", "onUpdate:valor"]))
                          : (column.key === 'Desconto')
                            ? (_openBlock(), _createBlock(_component_campo_numerico, {
                                key: 7,
                                title: column.title,
                                dataAttributeColuna: column.key,
                                valor: record.valorDesconto,
                                "onUpdate:valor": ($event: any) => ((record.valorDesconto) = $event),
                                emDigitacao: record.emDigitacao,
                                "onUpdate:emDigitacao": ($event: any) => ((record.emDigitacao) = $event),
                                monetario: true,
                                valorMaximo: 0,
                                classGroupCss: "hidden ss-campo-numero-table",
                                classCss: "hidden ss-campo-numero-table ss-venda-itens",
                                disabled: !_ctx.props.editavel,
                                onKeydown: [
                                  _withKeys(_withModifiers(($event: any) => (_ctx.exibirDetalhesProduto(index)), ["shift","exact","prevent"]), ["d"]),
                                  _withKeys(_withModifiers(($event: any) => (_ctx.editarItem(index)), ["shift","exact","prevent"]), ["e"]),
                                  _cache[14] || (_cache[14] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent","shift"]), ["tab"])),
                                  _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                                  _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                                ]
                              }, null, 8, ["title", "dataAttributeColuna", "valor", "onUpdate:valor", "emDigitacao", "onUpdate:emDigitacao", "disabled", "onKeydown"]))
                            : (column.key === 'Total')
                              ? (_openBlock(), _createBlock(_component_campo_numerico, {
                                  key: 8,
                                  title: column.title,
                                  dataAttributeColuna: column.key,
                                  valor: record.valorTotal,
                                  monetario: true,
                                  disabled: true,
                                  classGroupCss: "hidden ss-campo-numero-table",
                                  classCss: "hidden ss-campo-numero-table"
                                }, null, 8, ["title", "dataAttributeColuna", "valor"]))
                              : (column.key === 'Item')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("span", null, [
                                      _createElementVNode("a", {
                                        onClick: ($event: any) => (_ctx.editarItem(index))
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(record.descricao), 1)
                                      ], 8, _hoisted_6)
                                    ]),
                                    _createElementVNode("span", null, "Quantidade: " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.quantidade, record.casasDecimaisUnidade)) + "(" + _toDisplayString(record.siglaUnidade) + ") ", 1),
                                    _createElementVNode("span", null, "Vlr Unitário: " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.precoUnitario, record.casasDecimaisPrecoUnitario, true)), 1),
                                    _createElementVNode("span", null, "Desconto: " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.valorDesconto, 2, true)), 1),
                                    _createElementVNode("span", null, "Total: " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(record.valorTotal, 2, true)), 1),
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("a", {
                                        class: "visualizar",
                                        onClick: ($event: any) => (_ctx.editarItem(index))
                                      }, [
                                        _createVNode(_component_icone, { nome: "editar" })
                                      ], 8, _hoisted_8)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["loading", "columns", "data-source"]))
      ])
    ]),
    _createVNode(_component_detalhes_produto_modal, {
      visivel: _ctx.state.exibirDetalhesProduto,
      "onUpdate:visivel": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.exibirDetalhesProduto) = $event)),
      empresa: _ctx.empresa,
      definicaoProduto: _ctx.state.codigoDefinicaoSelecionada
    }, null, 8, ["visivel", "empresa", "definicaoProduto"])
  ]))
}