import ApiERP from '@/core/conectores/ApiERP';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IDTOMovimentoEstoque } from '@/models/DTO/Estoques/MovimentoEstoque/IDTOMovimentoEstoque';
import { EPeriodoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/EPeriodoMovimentoEstoque';
import { IParametrosConsultaMovimentoEstoque } from '@/models/ParametrosRequisicao/Estoques/MovimentoEstoque/IParametrosConsultaMovimentoEstoque';

/**
 * Serviço Movimentos de Estoques
 * Fornece todas regras de negócios e lógicas relacionado a Movimentações de Estoques.
 */

class ServicoMovimentoEstoque {
    endPoint = 'movimentos-estoques';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterListaTipos(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
      return result.data;
    }

    public async obterListaPeriodo(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/periodos`);
      return result.data;
    }

    public async obterMovimentacoes(parametrosConsulta:IParametrosConsultaMovimentoEstoque): Promise<IDTOMovimentoEstoque[]> {
      let parametrosAdicionais = '';

      parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      if (parametrosConsulta.estoques !== undefined) {
        parametrosConsulta.estoques.forEach((codigoEstoque) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `estoques=${codigoEstoque}`;
        });
      }

      if (parametrosConsulta.codigoProdutoDefinicao !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `codigoProdutoDefinicao=${parametrosConsulta.codigoProdutoDefinicao}`;
      }

      if (parametrosConsulta.recursos !== undefined) {
        parametrosConsulta.recursos.forEach((recurso) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `recursos=${recurso}`;
        });
      }

      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

        if (parametrosConsulta.periodo === EPeriodoMovimentoEstoque.Personalizado) {
          parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
          parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
        }
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterSaldoTotalDefinicaoProduto(produtoDefinicao: number, empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/saldo-total/${produtoDefinicao}/${empresa}`);
      return result.data;
    }
}
export default ServicoMovimentoEstoque;
