
import {
  computed, defineComponent, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo } from '@/models/Entidades/Fiscal/INotaFiscal';

export default defineComponent({
  name: 'NotaFiscalImpostos',
  props: {
    impostos: {
      type: Object as () => INotaFiscalImpostoTotal,
      required: true,
    },
    incidenciasCalculo: {
      type: Array as () => INotaFiscalIncidenciaCalculo[],
      default: [] as INotaFiscalIncidenciaCalculo[],
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    CampoNumerico,
  },
  emits: ['update:impostos', 'update:incidenciasCalculo', 'totalAlterado', 'calcularCusto'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedImpostos = computed({
      get: () => props.impostos,
      set: (val: INotaFiscalImpostoTotal) => {
        emit('update:impostos', val);
      },
    });

    const computedIncidenciasCalculo = computed({
      get: () => props.incidenciasCalculo,
      set: (val: INotaFiscalIncidenciaCalculo[]) => {
        emit('update:incidenciasCalculo', val);
      },
    });

    function totalAlterado() {
      emit('totalAlterado');
    }

    function obtemDescricaoIncidencia(identificador: string):string {
      if (identificador === 'OutrasDespesas') {
        return 'Outras Despesas';
      }

      return identificador;
    }

    return {
      props,
      state,
      computedImpostos,
      computedIncidenciasCalculo,
      totalAlterado,
      obtemDescricaoIncidencia,
    };
  },
});
