
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { INotaFiscalDetalhePagamento, INotaFiscalDetalhePagamentoAnexo, INotaFiscalDetalhePagamentoCentroCusto } from '@/models/Entidades/Fiscal/INotaFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import NotaFiscalDetalhePagamentoModal from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhePagamentoModal.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IFormaPagamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'NotaFiscalDetalhesPagamento',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    totalDetalhesPagamento: {
      type: Number,
      required: true,
    },
    detalhesPagamento: {
      type: Array as () => INotaFiscalDetalhePagamento[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    formaPagamento: {
      type: Object as () => IFormaPagamento,
      default: {} as IFormaPagamento,
    },
  },
  components: {
    MensagemSemDados,
    CampoNumerico,
    NotaFiscalDetalhePagamentoModal,
    Icone,
  },
  emits: ['update:detalhesPagamento', 'update:totalDetalhesPagamento', 'atualizarDetalhePagamento', 'excluirDetalhePagamento'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const state = reactive({
      colunasGrade: [] as IColumn[],
      idselecionados: [] as string[],
      indexSelecionado: -1,
      detalheSelecionado: {} as INotaFiscalDetalhePagamento,
      detalhesSelecionados: [] as INotaFiscalDetalhePagamento[],
      exibirLancamento: false,
      telaMobile: false,
    });

    const computedDetalhesPagamento = computed({
      get: () => props.detalhesPagamento,
      set: (val: INotaFiscalDetalhePagamento[]) => {
        emit('update:detalhesPagamento', val);
      },
    });

    const computedTotalDetalhesPagamento = computed({
      get: () => props.totalDetalhesPagamento,
      set: (val: number) => {
        emit('update:totalDetalhesPagamento', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Detalhe', dataIndex: 'detalhe', key: 'DetalhePagamento', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 1, visible: true, width: 120, ordering: false, align: 'left', ellipsis: true,
          },
          {
            title: 'Vencimento', dataIndex: 'vencimento', key: 'Vencimento', position: 3, visible: true, ordering: false, align: 'center', width: 100, ellipsis: true,
          },
          {
            title: 'Valor', dataIndex: 'valorTotal', key: 'ValorTotal', position: 4, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Conta', dataIndex: 'conta', key: 'Conta', position: 5, visible: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'Categoria', position: 6, visible: true, ordering: false, align: 'left', width: 250, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 9, visible: true, width: 70, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      state.idselecionados = selectedRowKeys;
    };

    function adicionarDetalhe() {
      state.indexSelecionado = -1;
      state.detalhesSelecionados = [] as INotaFiscalDetalhePagamento[];
      state.detalheSelecionado = {} as INotaFiscalDetalhePagamento;
      state.detalheSelecionado.statusTituloFinanceiro = EStatusTituloFinanceiro.Pendente;
      state.detalheSelecionado.alterado = false;
      state.detalheSelecionado.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
      state.detalheSelecionado.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
      state.exibirLancamento = true;
    }

    function editarDetalhe(index: number) {
      state.indexSelecionado = index;
      state.detalhesSelecionados = [] as INotaFiscalDetalhePagamento[];
      state.detalheSelecionado = computedDetalhesPagamento.value[index];
      if (state.detalheSelecionado.statusTituloFinanceiro !== EStatusTituloFinanceiro.Pendente) {
        apresentarMensagemAlerta('Título já baixado ou não disponível para alteração!');
      } else {
        state.exibirLancamento = true;
      }
    }

    function editarDetalhesSelecionados() {
      state.indexSelecionado = -1;
      state.detalheSelecionado = {} as INotaFiscalDetalhePagamento;
      state.detalhesSelecionados = [] as INotaFiscalDetalhePagamento[];

      state.idselecionados.forEach((id) => {
        const index = computedDetalhesPagamento.value.findIndex((d) => d.numeroTitulo === id);
        if (computedDetalhesPagamento.value[index].statusTituloFinanceiro === EStatusTituloFinanceiro.Pendente) {
          state.detalhesSelecionados.push(computedDetalhesPagamento.value[index]);
        }
      });

      if (UtilitarioGeral.validaLista(state.detalhesSelecionados)) {
        state.exibirLancamento = true;
      } else {
        apresentarMensagemAlerta('Título(s) já baixados ou não disponível para alteração.');
      }
    }

    function salvarDetalhe(detalhes: INotaFiscalDetalhePagamento[]) {
      if (state.indexSelecionado === -1 && state.idselecionados.length === 0) {
        detalhes.forEach((d) => {
          computedDetalhesPagamento.value.push(d);
        });
        state.indexSelecionado = computedDetalhesPagamento.value.length - 1;
        emit('atualizarDetalhePagamento', state.indexSelecionado);
      } else {
        detalhes.forEach((detalhe) => {
          const index = computedDetalhesPagamento.value.findIndex((d) => d.numeroTitulo === detalhe.numeroTitulo);
          computedDetalhesPagamento.value[index] = detalhe;
          emit('atualizarDetalhePagamento', index);
        });
      }
    }

    function removerDetalhe(index: number) {
      const codigoDetalhe = computedDetalhesPagamento.value[index].codigo;
      computedDetalhesPagamento.value.splice(index, 1);
      emit('excluirDetalhePagamento', codigoDetalhe);
    }

    function excluirDetalhe(index: number) {
      Modal.confirm({
        title: 'Você realmente deseja excluir o detalhe',
        content: `${computedDetalhesPagamento.value[index].numeroTitulo} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => {
          state.idselecionados = [] as string[];
          removerDetalhe(index);
        },
      });
    }

    function excluirDetalhesSelecionados() {
      Modal.confirm({
        title: 'Você realmente deseja excluir os detalhes selecionados?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => {
          state.idselecionados.forEach((id) => {
            const index = computedDetalhesPagamento.value.findIndex((d) => d.numeroTitulo === id);
            removerDetalhe(index);
          });
          state.idselecionados = [] as string[];
        },
      });
    }

    function calculaTotalDetalhePagamento(): number {
      let totalPagamento = 0;
      if (UtilitarioGeral.validaLista(computedDetalhesPagamento.value)) {
        computedDetalhesPagamento.value.forEach((d) => {
          totalPagamento += d.valorTotal;
        });
      }
      computedTotalDetalhesPagamento.value = totalPagamento;
      return totalPagamento;
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
    });

    return {
      props,
      state,
      UtilitarioMascara,
      UtilitarioData,
      computedDetalhesPagamento,
      computedTotalDetalhesPagamento,
      mudarSelecao,
      adicionarDetalhe,
      editarDetalhe,
      editarDetalhesSelecionados,
      salvarDetalhe,
      excluirDetalhe,
      excluirDetalhesSelecionados,
      calculaTotalDetalhePagamento,
    };
  },
});
