import { reactive } from 'vue';

export interface IRequisicaoAutorizacaoBase {
  permissao: string;
  descricao: string;
  exibirRequisicao: boolean;
}
export interface IRetornoRequisicaoAutorizacaoBase{
  requisicaoAutorizacaoBase: IRequisicaoAutorizacaoBase;
}
export function useRequisicaoAutorizacaoBase():IRetornoRequisicaoAutorizacaoBase {
  const requisicaoAutorizacaoBase = reactive({
    permissao: '',
    descricao: '',
    exibirRequisicao: false,
  });

  return {
    requisicaoAutorizacaoBase,
  };
}
