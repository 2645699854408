
import { computed, defineComponent, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IArquivo } from '@/models/Entidades/MeuSistema/IArquivo';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { INotaFiscalDetalhePagamentoAnexo } from '@/models/Entidades/Fiscal/INotaFiscal';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';

export default defineComponent({
  name: 'NotaFiscalDetalhePagamentoAnexo',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    anexos: {
      type: Array as () => INotaFiscalDetalhePagamentoAnexo[],
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
  },
  emits: ['update:anexos'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const state = reactive({
      enviandoArquivos: false,
      excluindoArquivos: false,
    });

    const servicoNotaFiscal = new ServicoNotaFiscal();
    const computedAnexosNotaFiscal = computed({
      get: () => props.anexos,
      set: (valor: INotaFiscalDetalhePagamentoAnexo[]) => {
        emit('update:anexos', valor);
      },
    });

    async function removerAnexo(codigo:number, codigoArquivo: number) {
      state.excluindoArquivos = true;
      const retornoArquivo = await servicoNotaFiscal.removerAnexo(codigo, codigoArquivo);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        const index = computedAnexosNotaFiscal.value.findIndex((c) => c.codigoArquivo === codigoArquivo);
        if (index !== -1) {
          computedAnexosNotaFiscal.value.splice(index, 1);
        }
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.excluindoArquivos = false;
    }

    async function visualizarAnexo(arquivo:IArquivo) {
      if (!UtilitarioGeral.valorValido(arquivo.link)) {
        const retornoArquivo = await servicoNotaFiscal.gerarLinkAnexo(arquivo.pasta, arquivo.nome);
        if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
          UtilitarioGeral.downloadArquivo(retornoArquivo.linkArquivo);
        } else {
          apresentarMensagemAlerta(retornoArquivo.mensagem);
        }
      } else {
        UtilitarioGeral.downloadArquivo(arquivo.link);
      }
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarAnexoNotaFiscal');
      if (elemento !== null) {
        elemento.click();
      }
    }
    function confirmaExclusao(notaFiscalDetalhePagamentoAnexo: INotaFiscalDetalhePagamentoAnexo) {
      Modal.confirm({
        title: 'Você deseja remover o anexo:',
        content: `${notaFiscalDetalhePagamentoAnexo.arquivo.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerAnexo(notaFiscalDetalhePagamentoAnexo.codigo, notaFiscalDetalhePagamentoAnexo.codigoArquivo); },
      });
    }
    function novoAnexo(retorno: IRetornoArquivo) {
      const notaFiscalDetalhePagamentoAnexo:INotaFiscalDetalhePagamentoAnexo = {} as INotaFiscalDetalhePagamentoAnexo;
      notaFiscalDetalhePagamentoAnexo.codigo = 0;
      notaFiscalDetalhePagamentoAnexo.codigoArquivo = retorno.codigoArquivo;
      notaFiscalDetalhePagamentoAnexo.arquivo = {} as IArquivo;
      notaFiscalDetalhePagamentoAnexo.arquivo.nome = retorno.arquivo;
      notaFiscalDetalhePagamentoAnexo.arquivo.link = retorno.linkArquivo;
      notaFiscalDetalhePagamentoAnexo.arquivo.tamanhoApresentacao = retorno.tamanhoApresentacao;
      computedAnexosNotaFiscal.value.push(notaFiscalDetalhePagamentoAnexo);
    }
    async function enviarArquivos(event:any) {
      state.enviandoArquivos = true;
      const retornoAnexos = await servicoNotaFiscal.enviarAnexos(event.target.files, props.empresa);
      retornoAnexos.forEach((retorno) => {
        if (retorno.status) {
          novoAnexo(retorno);
        }
      });

      state.enviandoArquivos = false;
    }

    async function drop(event:any) {
      state.enviandoArquivos = true;
      const retornoAnexos = await servicoNotaFiscal.enviarAnexos(event.dataTransfer.files, props.empresa);
      retornoAnexos.forEach((retorno) => {
        if (retorno.status) {
          novoAnexo(retorno);
        }
      });
      state.enviandoArquivos = false;
    }

    return {
      props,
      computedAnexosNotaFiscal,
      state,
      confirmaExclusao,
      drop,
      enviarArquivos,
      visualizarAnexo,
      selecionarArquivoUpload,
    };
  },
});
