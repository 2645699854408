
import {
  computed, defineComponent, reactive, ref, watch,
} from 'vue';

import ServicoUnidade from '@/servicos/Cadastros/Produtos/ServicoUnidade';
import { IDTOUnidadeProdutoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOUnidadeProdutoMovimento';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { EVinculoUnidade } from '@/models/Enumeradores/Cadastros/Produtos/EVinculoUnidade';

export default defineComponent({
  name: 'SelecionarUnidadeProdutoMovimento',
  props: {
    codigoUnidadeSelecionada: {
      type: Number,
      default: 0,
    },
    codigoProdutoDefinicao: {
      type: Number,
      required: true,
    },
    descricao: {
      type: String,
      default: '',
    },
    sigla: {
      type: String,
      default: '',
    },
    fracionar: {
      type: Boolean,
      default: false,
    },
    fatorConversao: {
      type: Number,
      default: 1,
    },
    casasDecimais: {
      type: Number,
      default: 0,
    },
    percentualFormacaoPreco: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    apresentarApenasSigla: {
      type: Boolean,
      default: false,
    },
    apenasUnidadePrincipal: {
      type: Boolean,
      default: false,
    },
    valorCodigosProduto: {
      type: String,
      default: '',
    },
    editavel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoUnidadeSelecionada', 'update:descricao', 'update:sigla', 'update:fracionar', 'update:casasDecimais', 'update:fatorConversao', 'update:percentualFormacaoPreco', 'change', 'blur'],
  setup(props, { emit }) {
    const refSelecionarUnidade = ref<HTMLElement | null>(null);

    const servicoUnidade = new ServicoUnidade();

    const state = reactive({
      unidades: [] as IDTOUnidadeProdutoMovimento[],
      buscandoDados: false,
    });

    const computedDescricao = computed({
      get: () => props.descricao,
      set: (val: string) => {
        emit('update:descricao', val);
      },
    });

    const computedSigla = computed({
      get: () => props.sigla,
      set: (val: string) => {
        emit('update:sigla', val);
      },
    });

    const computedFracionar = computed({
      get: () => props.fracionar,
      set: (val: boolean) => {
        emit('update:fracionar', val);
      },
    });

    const computedFatorConversao = computed({
      get: () => props.fatorConversao,
      set: (val: number) => {
        emit('update:fatorConversao', val);
      },
    });

    const computedCasasDecimais = computed({
      get: () => props.casasDecimais,
      set: (val: number) => {
        emit('update:casasDecimais', val);
      },
    });

    const computedPercentualFormacaoPreco = computed({
      get: () => props.percentualFormacaoPreco,
      set: (val: number) => {
        emit('update:percentualFormacaoPreco', val);
      },
    });

    function adicionarUnidadePorPropriedades(codigoUnidadeSelecionada: number) {
      const unidade:IDTOUnidadeProdutoMovimento = {} as IDTOUnidadeProdutoMovimento;
      unidade.codigo = codigoUnidadeSelecionada;
      unidade.descricao = props.descricao;
      unidade.sigla = props.sigla;
      unidade.fracionar = props.fracionar;
      unidade.casasDecimais = props.casasDecimais;
      unidade.fatorConversao = props.fatorConversao;
      unidade.percentualFormacaoPreco = props.percentualFormacaoPreco;
      state.unidades.push(unidade);
    }

    function atualizarDadosUnidadeSelecionada(codigoUnidadeSelecionada: number) {
      if (codigoUnidadeSelecionada > 0) {
        if (UtilitarioGeral.validaLista(state.unidades)) {
          const unidadeSelecionada = state.unidades.find((c) => c.codigo === codigoUnidadeSelecionada);
          if (unidadeSelecionada !== undefined) {
            computedDescricao.value = unidadeSelecionada.descricao;
            computedSigla.value = unidadeSelecionada.sigla;
            computedFracionar.value = unidadeSelecionada.fracionar;
            computedCasasDecimais.value = unidadeSelecionada.casasDecimais;
            computedFatorConversao.value = unidadeSelecionada.fatorConversao;
            computedPercentualFormacaoPreco.value = unidadeSelecionada.percentualFormacaoPreco;
            return;
          }
        }
        adicionarUnidadePorPropriedades(codigoUnidadeSelecionada);
      }
    }
    function obterCodigoUnidade():any {
      atualizarDadosUnidadeSelecionada(props.codigoUnidadeSelecionada);

      return (props.codigoUnidadeSelecionada === 0 ? undefined : props.codigoUnidadeSelecionada);
    }

    const computedCodigoUnidadeSelecionada = computed({
      get: () => obterCodigoUnidade(),
      set: (codigoUnidadeSelecionada: number) => {
        emit('update:codigoUnidadeSelecionada', codigoUnidadeSelecionada);
        atualizarDadosUnidadeSelecionada(codigoUnidadeSelecionada);
      },
    });

    async function obterUnidadesProduto() {
      state.buscandoDados = true;
      state.unidades = [];
      state.unidades = await servicoUnidade.obterUnidadesProdutoMovimento(props.codigoProdutoDefinicao, props.apenasUnidadePrincipal, props.valorCodigosProduto);
      state.buscandoDados = false;
    }

    function change() {
      emit('change', props.codigoUnidadeSelecionada);
    }

    function focus() {
      if (refSelecionarUnidade.value) {
        refSelecionarUnidade.value.focus();
      }
    }
    function blur() {
      emit('blur');
    }

    function obtemApresentacaoUnidade(unidade:IDTOUnidadeProdutoMovimento):string {
      if (props.apresentarApenasSigla) {
        return unidade.sigla;
      }

      return `${unidade.sigla} - ${unidade.descricao}`;
    }

    async function verificarBuscaUnidade() {
      if (props.editavel) {
        await obterUnidadesProduto();
      }
    }
    watch(async () => props.codigoProdutoDefinicao, async () => {
      if (!UtilitarioGeral.validaCodigo(props.codigoUnidadeSelecionada) && props.codigoProdutoDefinicao > 0 && !UtilitarioGeral.validaLista(state.unidades)) {
        await obterUnidadesProduto();
        if (UtilitarioGeral.validaLista(state.unidades)) {
          const unidadePrincipal = state.unidades.find((c) => c.vinculo === EVinculoUnidade.UnidadePrincipal);
          if (unidadePrincipal !== undefined && props.codigoUnidadeSelecionada === 0) {
            emit('update:codigoUnidadeSelecionada', unidadePrincipal.codigo);
            computedDescricao.value = unidadePrincipal.descricao;
            computedSigla.value = unidadePrincipal.sigla;
            computedFracionar.value = unidadePrincipal.fracionar;
            computedCasasDecimais.value = unidadePrincipal.casasDecimais;
            computedFatorConversao.value = unidadePrincipal.fatorConversao;
            computedPercentualFormacaoPreco.value = unidadePrincipal.percentualFormacaoPreco;
          }
        }
      } else {
        atualizarDadosUnidadeSelecionada(props.codigoUnidadeSelecionada);
      }
    }, { immediate: true });

    function aguardarConclusaoCarregamento(codigoUnidadeSelecionada: number):boolean {
      return (!UtilitarioGeral.validaLista(state.unidades) && (UtilitarioGeral.validaCodigo(codigoUnidadeSelecionada)));
    }

    return {
      props,
      state,
      refSelecionarUnidade,
      computedCodigoUnidadeSelecionada,
      change,
      focus,
      blur,
      obtemApresentacaoUnidade,
      aguardarConclusaoCarregamento,
      verificarBuscaUnidade,
    };
  },
});
