
import {
  computed, defineComponent, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumericoMedida from '@/core/components/Tela/CampoNumericoMedida.vue';
import { INotaFiscalTransporteVolume } from '@/models/Entidades/Fiscal/INotaFiscal';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'NotaFiscalFreteVolume',
  props: {
    volume: {
      type: Object as () => INotaFiscalTransporteVolume,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    CampoNumericoMedida,
    Icone,
  },
  emits: ['update:volume', 'campoAlterado', 'excluir'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedVolume = computed({
      get: () => props.volume,
      set: (val: INotaFiscalTransporteVolume) => {
        emit('update:volume', val);
      },
    });

    function campoAlterado() {
      emit('campoAlterado');
    }

    function excluir() {
      emit('excluir');
    }

    return {
      props,
      state,
      computedVolume,
      campoAlterado,
      excluir,
    };
  },
});
